<template>
  <div class="row">
    <v-col cols="12" sm="12">
      <b-alert
          show
          variant="light"
          class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
      >
        <div class="alert-text mt-2 text-center">
          <h1 class="black--text"><b>Payments</b></h1>
        </div>
      </b-alert>
    </v-col>
    <div class="col-sm-12">
      <p>
        Payment of fees and costs could be made by bank transfer at the following
        details:
      </p>
      <div class="c-content-panel">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table custom-table" style="margin: 0">
                <tbody>
                <tr>
                  <td>Account</td>
                  <td>TCC ADR Centre</td>
                </tr>
                <tr>
                  <td>Bank</td>
                  <td>Bank Al-Falah Limited, DHA Phase V Branch, Lahore,
                    Pakistan
                  </td>
                </tr>
                <tr>
                  <td>Account number</td>
                  <td>09601008103213</td>
                </tr>
                <tr>
                  <td>IBAN</td>
                  <td>PK96ALFH0960001008103213</td>
                </tr>
                <tr>
                  <td>Currency</td>
                  <td>PKR</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <p class="c-font-bold">Note:</p>
      <p>
        It is important to include the name of the payer and, if applicable, the
        case reference to the payment for its prompt and accurate crediting. A copy
        of the proof of payment should be provided, so that the respective transfer
        is located in the TCC’s accounts.
      </p>
      <p>
        All payments must be free of any charges that may occur at the sending
        and/or receiving bank. TCC shall bear no responsibility with regards to any
        currency conversions that might be applied by the sending and/or the
        receiving bank or to any currency fluctuations, which might overall affect
        the payment of fees and costs.
      </p>
      <p>
        <a style="color: blue" href="mailto:info@tccadr.org">info@tccadr.org</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "payments",
  components: {},
  mounted() {
  },
  methods: {}
};
</script>
